import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'
import { useTranslations } from '../hooks/use-translations'

import Photo360Gif from '../assets/images/solutions/execution-phase/photo360.gif'

import * as s from '../pages/news.module.scss'

const ExecutionPhase = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title={t('seo_title_execution_phase')}
        description="During the project's construction stage, progress tracking is essential to ensure tasks are completed on time. Regular QA/QC inspections are conducted to maintain quality standards, while schedule monitoring helps identify deviations and allows for timely adjustments to keep the project on track."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            {t('date_execution_phase')}
          </Typography>
          <Typography variant="h1" color="blue" size={40} mb={48}>
            {t('title_execution_phase')}
          </Typography>
          <Typography variant="body1" size={18}>
            {t('execution_phase_text')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('execution_phase_subtitle')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('execution_phase_text1')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/execution-phase/image-1.png"
                alt="Work scheduling and construction process visualization"
                title="Work scheduling and construction process visualization"
                placeholder="blurred"
              />
            </div>
          </div>
          <Typography variant="h2" size={24} mt={48} mb={16}>
            {t('execution_phase_subtitle2')}
          </Typography>
          <Typography variant="body1" size={18} mb={32}>
            {t('execution_phase_text2')}
          </Typography>
          <div className={s.contentWrap}>
            <StaticImage
              width={564}
              src="../assets/images/solutions/execution-phase/image-2.png"
              alt="Construction Data integration"
              title="Construction Data integration"
              placeholder="blurred"
            />
            <StaticImage
              width={564}
              src="../assets/images/solutions/execution-phase/image-3.png"
              alt="Construction Data integration 2"
              title="Construction Data integration 2"
              placeholder="blurred"
            />
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('execution_phase_subtitle3')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('execution_phase_text3')}
              </Typography>
            </div>
            <div>
              <img
                width={564}
                src={Photo360Gif}
                alt="Construction project documentation"
                title="Construction project documentation"
                loading="lazy"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('execution_phase_subtitle4')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('execution_phase_text4')}
              </Typography>
            </div>
            <div>
              <StaticImage
                width={564}
                src="../assets/images/solutions/execution-phase/image-4.png"
                alt="As-built model development"
                title="As-built model development"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate(getPath('/bim-services/'))}
          >
            {t('Other BIM services')}
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default ExecutionPhase

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
